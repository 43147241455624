import React, { useState, useEffect, useContext } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { motion } from "framer-motion";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import TextField from "@mui/material/TextField";
import { Swiper, SwiperSlide } from "swiper/react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { MdOutlineClose, MdShoppingBasket } from "react-icons/md";
import ModalPhoto from "../components/product/ModalPhoto";
import MetaTag from "../components/metaTag";
import {
  toggleButtonSx,
  toggleContainerSx,
  textFieldSx,
} from "../components/product/mui.style";
import {
  previewContainer,
  previewImage,
  image,
  settingsElement,
  additionalSettings,
  toggle,
} from "../style/pages/lhdfsubstrates.module.scss";
import {
  detail,
  settingsContainer,
  titleSettings,
  priceInfo,
  mobCloseBtn,
  closeBtn,
  costBundle,
  detailHeader,
  settings,
  settingsBlock,
  productInfo,
  settingsList,
  orderInfo,
  productContainer,
  productItem,
  delProduct,
  mobileProducts,
  hint,
  nubmer,
} from "../components/product/product.module.scss";

import { StoreContext } from "../context/store-context";

import dataJson from "../data/substrates.json";

// Пояснение к компоненту: добавились другие цены к черному лхвф, но диаметр везде один и тот же. Поэтому диаметр берется по умолчанию с белого лхдф, а цена рассчитывается исходя из выбранного цвета

function Lhdfsubstrates() {
  const imageData = useStaticQuery(graphql`
    {
      allFile(
        filter: {
          relativeDirectory: { eq: "substrates/lhdf" }
          name: { nin: ["main"] }
        }
        sort: { order: ASC, fields: name }
      ) {
        nodes {
          childImageSharp {
            id
            gatsbyImageData(
              breakpoints: [750, 1366, 1920]
              formats: [AUTO, AVIF, WEBP]
              quality: 85
              placeholder: DOMINANT_COLOR
            )
          }
        }
      }
    }
  `);

  const [selectedImg, setSelectedImg] = useState(null);

  const { store, setStore } = useContext(StoreContext);
  const [totalPrice, setTotalPrice] = useState(0);

  const [diameter, setDiameter] = useState(null);
  const [color, setColor] = useState(null);

  const [dateCost, setDateCost] = useState([]);

  const [engravingText, setEngravingText] = useState("");

  const [quantity, setQuantity] = useState(0);

  const [totalAmount, setTotalAmount] = useState(0);

  const [mobileList, setMobileList] = useState(false);

  // Данные для подложек из лхдф
  const lhdfWhiteData = dataJson.lhdf.white;
  const lhdfBlackData = dataJson.lhdf.black;
  // Данные для цветов лхдф
  const colorLhdf = dataJson.lhdf.colors;

  // Обновить состояние для диаметра
  const handleDiameter = (event, newAlignment) => {
    setDiameter(newAlignment);
  };

  // Обновить состояние для текста гравировки
  const handleEngraving = (event) => {
    setEngravingText(event.target.value);
  };

  // Обновить состояние для количества подложек
  const handleQuantity = (event) => {
    if (Number(event.target.value) >= 0) {
      setQuantity(Number(event.target.value));
    } else return;
  };

  // Обновить состояние для цвета
  const handleColor = (event, newAlignment) => {
    if (newAlignment === "Черный") {
      setDateCost(lhdfBlackData);
    } else setDateCost(lhdfWhiteData);

    setColor(newAlignment);
  };

  // Добавить первый набор подложек
  const addProduts = () => {
    const dataOrder = {
      diameter: diameter,
      color: color,
      engravingText: engravingText,
      quantity: quantity,
      totalAmount: totalAmount,
    };

    setTotalPrice(totalPrice + dataOrder.totalAmount);

    setStore({
      ...store,
      totalPrice: store.totalPrice + dataOrder.totalAmount,
      substrates: {
        lhdf: {
          bundle: [...store.substrates.lhdf.bundle, dataOrder],
          totalPrice: store.substrates.lhdf.totalPrice + dataOrder.totalAmount,
        },
        acrylic: { ...store.substrates.acrylic },
        plywood: { ...store.substrates.plywood },
      },
      acrylicfalsebeads: { ...store.acrylicfalsebeads },
      frame: { ...store.frame },
      toppers: { ...store.toppers },
      screens: { ...store.screens },
      spatulas: { ...store.spatulas },
      tag: { ...store.tag },
      sticks: { ...store.sticks },
      stand: { ...store.stand },
    });

    setDiameter(null);
    setColor("");
    setEngravingText("");

    setQuantity(0);
    setTotalAmount(0);
  };

  // Удалить набор подложек
  const deleteProduct = (index) => {
    // Массив всех наборов
    const arrBundle = [...store.substrates.lhdf.bundle];
    // Цена набора, который нужно удалить
    const deductibleAmount = store.substrates.lhdf.bundle[index].totalAmount;

    // Удаляем из массива наборов элемент с заданным index
    arrBundle.splice(index, 1);
    setStore({
      ...store,
      totalPrice: store.totalPrice - deductibleAmount,
      substrates: {
        lhdf: {
          bundle: [...arrBundle],
          totalPrice: store.substrates.lhdf.totalPrice - deductibleAmount,
        },
        acrylic: { ...store.substrates.acrylic },
        plywood: { ...store.substrates.plywood },
      },
      acrylicfalsebeads: { ...store.acrylicfalsebeads },
      frame: { ...store.frame },
      toppers: { ...store.toppers },
      screens: { ...store.screens },
      spatulas: { ...store.spatulas },
      tag: { ...store.tag },
      sticks: { ...store.sticks },
      stand: { ...store.stand },
    });
  };

  useEffect(() => {
    let q = quantity || 0;
    let c =
      diameter && color
        ? dateCost.find((item) => item.diameter === diameter).cost
        : 0;

    if (quantity >= 100 && quantity < 300) {
      setTotalAmount(Math.ceil(c * q - c * q * 0.06));
    } else if (quantity >= 300) {
      setTotalAmount(Math.ceil(c * q - c * q * 0.1));
    } else {
      setTotalAmount(Math.ceil(c * q));
    }
  }, [quantity, diameter, color, dateCost]);

  return (
    <main className="mainpage">
      <MetaTag title="Подложки из ЛХДФ | Wowmilota" />
      <motion.div
        layoutId="lhdfContainer"
        transition={{ type: "spring", bounce: 0.05, mass: 0.1 }}
        className={detail}
      >
        {selectedImg && (
          <ModalPhoto
            selectedImg={selectedImg}
            setSelectedImg={setSelectedImg}
          />
        )}

        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.4 }}
          className={previewContainer}
        >
          {imageData?.allFile?.nodes.map((img, index) => {
            const { childImageSharp } = img;
            const src = getImage(img);

            return (
              <motion.div
                onClick={() => setSelectedImg(src)}
                onKeyDown={() => setSelectedImg(src)}
                className={previewImage}
                key={childImageSharp.id}
                whileHover={{ scale: 0.98 }}
              >
                <GatsbyImage
                  image={src}
                  alt="Фотография подложки из лхдф"
                  className={image}
                />
              </motion.div>
            );
          })}
        </motion.div>

        <div className={settingsContainer}>
          <div className={settings}>
            <div className={settingsBlock}>
              <div className={productInfo}>
                <div className={detailHeader}>
                  <h2>Подложки из ЛХДФ</h2>
                </div>
                <p>
                  Современные подложки по приятным ценам.
                  <br />
                  <br />
                  Подложки из ЛХДФ - это древесные подложки с ламинированным
                  покрытием верхней части подложки (черное или белое), на
                  котором идеально контрастно смотрится гравировка вашего
                  логотипа, любой текст или пожелание.
                  <br />
                  <br />
                  Просты в уходе, с них легко смывается остатки не нужного
                  крема.
                  <br />
                  <br />
                  Толщина подложки ЛХДФ - 3мм. Но при использовании данных
                  подложек, следует помнить, что максимальный допустимый вес
                  торта 3,5кг при диаметре 30см. При большей нагрузке может
                  пойти прогиб. В случае, если планируется сборка тяжелого
                  торта, на такой подложке, то рекомендуем ее усилить.
                  <br />
                  <br />
                  Нанесение небольшой гравировки - бесплатно
                  <br />
                  <br />
                  При заказе от 100шт скидка 7%
                </p>
              </div>

              <h3 className={titleSettings}>Обязательные параметры</h3>

              <div className={settingsList}>
                <div
                  className={settingsElement}
                  style={{
                    backgroundColor: diameter
                      ? "rgba(255, 255, 255, 0.15)"
                      : "rgba(255, 255, 255, 0.05)",
                  }}
                >
                  <h3>Диаметр (см)</h3>
                  <ToggleButtonGroup
                    size="medium"
                    value={diameter}
                    exclusive
                    onChange={handleDiameter}
                    sx={toggleContainerSx}
                  >
                    {lhdfWhiteData.map((item) => {
                      const { diameter } = item;

                      return (
                        <ToggleButton
                          value={diameter}
                          key={`diameter${diameter}`}
                          sx={toggleButtonSx}
                        >
                          {diameter}
                        </ToggleButton>
                      );
                    })}
                  </ToggleButtonGroup>
                </div>

                <div
                  className={settingsElement}
                  style={{
                    backgroundColor: color
                      ? "rgba(255, 255, 255, 0.15)"
                      : "rgba(255, 255, 255, 0.05)",
                  }}
                >
                  <h3>Цвет</h3>
                  <ToggleButtonGroup
                    size="medium"
                    value={color}
                    exclusive
                    onChange={handleColor}
                    sx={toggleContainerSx}
                    key={`colorToggle`}
                  >
                    {colorLhdf.map((item) => {
                      return (
                        <ToggleButton
                          value={item}
                          key={`color${item}`}
                          sx={toggleButtonSx}
                        >
                          {item}
                        </ToggleButton>
                      );
                    })}
                    ;
                  </ToggleButtonGroup>
                </div>

                <div
                  className={settingsElement}
                  style={{
                    backgroundColor:
                      quantity > 0
                        ? "rgba(255, 255, 255, 0.15)"
                        : "rgba(255, 255, 255, 0.05)",
                  }}
                >
                  <h3>Количество</h3>
                  <div className={toggle}>
                    <TextField
                      id="acrylicQuantity"
                      variant="standard"
                      type="number"
                      onFocus={(e) => e.target.value === "0" && setQuantity("")}
                      onChange={handleQuantity}
                      value={quantity}
                      sx={textFieldSx}
                      inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                    />
                  </div>
                </div>
              </div>

              <h3 className={titleSettings}>Дополнительные параметры</h3>

              <div className={additionalSettings}>
                <div
                  className={settingsElement}
                  style={{
                    backgroundColor: engravingText
                      ? "rgba(255, 255, 255, 0.15)"
                      : "rgba(255, 255, 255, 0.05)",
                  }}
                >
                  <h3>Гравировка</h3>
                  <div className={toggle}>
                    <TextField
                      fullWidth
                      id="lhdfEngravingText"
                      label="Кратко опишите"
                      variant="standard"
                      value={engravingText}
                      onChange={handleEngraving}
                      sx={textFieldSx}
                    />
                  </div>
                </div>
                <div
                  className={settingsElement}
                  style={{ backgroundColor: "rgba(255, 255, 255, 0.15)" }}
                >
                  <h3>Толщина</h3>
                  <p>3мм</p>
                </div>
              </div>
            </div>

            <div className={orderInfo}>
              <h3>Ваши наборы</h3>
              {store?.substrates.lhdf.totalPrice > 0 ? (
                <div className={productContainer}>
                  {store?.substrates.lhdf.bundle.map((item, index) => {
                    return (
                      <div key={`order_${index}`} className={productItem}>
                        <button
                          className={delProduct}
                          aria-label="Удалить"
                          onClick={() => deleteProduct(index)}
                        >
                          <MdOutlineClose color="white" />
                        </button>
                        <h3>Набор {index + 1}</h3>
                        <p>{item.diameter} см</p>
                        <p>{item.color}</p>
                        {item.engravingText ? (
                          <p>С гравировкой</p>
                        ) : (
                          <p>Без гравировкой</p>
                        )}
                        <p>{item.quantity} шт.</p>
                        <p>{item.totalAmount} руб.</p>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div className={hint}>
                  <p>
                    Пока у Вас нет наборов.
                    <br />
                    <br />
                    Когда Вы выберите все обязательные параметры, кнопка внизу
                    покажет стоимость набора.
                    <br /> <br />
                    Вы можете формировать любое количество наборов, просто
                    продолжайте выбирать параметры.
                  </p>
                </div>
              )}
            </div>
          </div>
          <div className={priceInfo}>
            <div className={mobileProducts}>
              <button onClick={() => setMobileList(!mobileList)}>
                <MdShoppingBasket color="white" />
                <p className={nubmer}>{store?.substrates.lhdf.bundle.length}</p>
              </button>
              {mobileList && (
                <Swiper
                  slidesPerView={"auto"}
                  spaceBetween={10}
                  grabCursor={true}
                  className={productContainer}
                >
                  {store?.substrates.lhdf.bundle.map((item, index) => {
                    return (
                      <SwiperSlide
                        key={`order_${index}`}
                        className={productItem}
                      >
                        <button
                          className="delProduct"
                          aria-label="Удалить"
                          onClick={() => deleteProduct(index)}
                        >
                          <MdOutlineClose color="white" />
                        </button>
                        <h3>Набор {index + 1}</h3>
                        <p>{item.diameter} см</p>
                        <p>{item.color}</p>
                        {item.engravingText ? (
                          <p>С гравировкой</p>
                        ) : (
                          <p>Без гравировкой</p>
                        )}
                        <p>{item.quantity} шт.</p>
                        <p>{item.totalAmount} руб.</p>
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              )}
            </div>
            {totalAmount > 0 && color && diameter ? (
              <button className={costBundle} onClick={addProduts}>
                {totalAmount > 0
                  ? `Добавить / ${totalAmount} руб.`
                  : "Сформируйте набор"}
              </button>
            ) : (
              <p className={costBundle}>Сформируйте набор</p>
            )}
            <Link to="/" className={mobCloseBtn}>
              <MdOutlineClose color="white" />
            </Link>
            <Link to="/" className={closeBtn}>
              {store?.substrates.lhdf.bundle.length > 0
                ? `Сохранить и закрыть`
                : `закрыть`}
            </Link>
          </div>
        </div>
      </motion.div>
    </main>
  );
}

export default Lhdfsubstrates;
